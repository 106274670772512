const MainRoutes = {
    path: "/main",
    meta: {
        requiresAuth: true
    },
    redirect: "/main",
    component: () => import("@/layouts/full/FullLayout.vue"),
    children: [
        {
            name: "Dashboard",
            path: "/dashboard",
            component: () => import("@/views/dashboard/dashboard.vue")
        },
        {
            name: "Verifications",
            path: "/verifications",
            component: () => import("@/views/verifications/verifications.vue")
        },
        {
            name: "Lists",
            path: "/lists",
            props: false,
            component: () => import("@/views/lists/lists.vue")
        },
        {
            name: "Widgets",
            path: "/widgets",
            props: false,
            component: () => import("@/views/widgets/widgets.vue")
        },
        {
            name: "Zapier",
            path: "/zapier",
            props: false,
            component: () => import("@/views/zapier/zapier.vue")
        },
        {
            name: "List",
            path: "/lists/:listId",
            props: true,
            component: () => import("@/views/lists/list.vue")
        },
        {
            name: "Credentials",
            path: "/apikeys",
            component: () => import("@/views/apikeys/apikeys.vue")
        },
        {
            name: "Webhooks",
            path: "/webhooks",
            component: () => import("@/views/webhooks/webhooks.vue")
        },
        {
            name: "Integrations",
            path: "/integrations",
            component: () => import("@/views/integrations/integrations.vue")
        },
        {
            name: "Integration OAuth",
            path: "/integrations/oauth/:service",
            component: () => import("@/views/integrations/oauth.vue")
        },
        {
            name: "Integration",
            path: "/integrations/:service",
            component: () => import("@/views/integrations/integration.vue")
        },
        {
            name: "Settings",
            path: "/users/me",
            component: () => import("@/views/users/settings.vue")
        }
    ]
};

export default MainRoutes;
