import { createRouter, createWebHistory } from "vue-router";
import MainRoutes from "./MainRoutes";
import AuthRoutes from "./AuthRoutes";
import { useUsersStore } from "@/stores/users";
import { useSessionsStore } from "@/stores/sessions";
import { logger } from "@/utils/helpers/logger";

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/:pathMatch(.*)*",
            component: () => import("@/views/Error.vue")
        },
        MainRoutes,
        AuthRoutes
    ]
});

router.beforeEach(async (to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/users/login", "/users/register", "/users/password/forgot", "/users/password/reset"];
    const authRequired = !publicPages.includes(to.path);
    const { session } = useSessionsStore();
    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authRequired) {
        if (!session?.token) {
            return next("/users/login");
        } else next();
    } else {
        if (!session?.token) {
            next();
        } else {
            return next("/dashboard");
        }
    }
    // } else {
    //     next();
    // }
});
