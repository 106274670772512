import { defineStore } from "pinia";
import { $api, getApiUrl } from "@/utils/helpers/api";
import { useSessionsStore } from "@/stores/sessions";
import type { UserObject } from "@/types/UserObject";
import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
const baseUrl = `${getApiUrl()}/users`;

export const useUsersStore = defineStore({
    id: "users",
    state: () => ({
        // @ts-ignore
        notifications: {},
        user: {} as UserObject
    }),
    actions: {
        async register(fname: string, pwd: string, email: string, recaptchaToken: string) {
            return await $api.post(baseUrl, null, { fname, pwd, email, recaptchaToken });
        },
        async getCurrentUser() {
            const sessions = useSessionsStore();
            $api.get(`${baseUrl}/${sessions.session?.user.id}`).then((response: AxiosResponse | AxiosError) => {
                if (!axios.isAxiosError(response)) {
                    this.user = response.data.user as UserObject;
                }
                return response;
            });
        },
        async updateUser() {
            const sessions = useSessionsStore();
            return await $api
                .post(`${baseUrl}/${sessions.session?.user.id}`, null, {
                    iname: this.user.iname,
                    email: this.user.email,
                    notifications: this.user.notifications
                })
                .then((response: AxiosResponse | AxiosError) => {
                    if (!axios.isAxiosError(response)) {
                        this.user = response.data.user as UserObject;
                    }
                    return response;
                });
        }
    }
});
