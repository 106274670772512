import { defineStore } from "pinia";
import { router } from "@/router";
import { $api, getApiUrl } from "@/utils/helpers/api";
import type { AxiosError, AxiosResponse } from "axios";
import type { SessionObject } from "@/types/SessionObject";
import { logger } from "@/utils/helpers/logger";
import axios from "axios";

export const useSessionsStore = defineStore({
    id: "sessions",
    state: () => ({
        // @ts-ignore
        session: <SessionObject | null>JSON.parse(localStorage.getItem("session"))
    }),
    actions: {
        async get(sessionId: string) {
            const baseUrl = `${getApiUrl()}/sessions/${sessionId}`;
            return await $api.get(baseUrl, null).then((response: AxiosResponse | AxiosError) => {
                if (!axios.isAxiosError(response)) {
                    if (response?.data?.session) {
                        this.session = response?.data?.session as SessionObject;
                        localStorage.setItem("session", JSON.stringify(this.session));
                        router.push("/dashboard");
                    }
                }
                return response;
            });
        },
        async login(email: string, password: string, recaptchaToken?: string) {
            const baseUrl = `${getApiUrl()}/sessions`;
            return await $api.post(baseUrl, null, { email, password, recaptchaToken }).then((response: AxiosResponse | AxiosError) => {
                if (!axios.isAxiosError(response)) {
                    if (response?.data?.session) {
                        this.session = response?.data?.session as SessionObject;
                        localStorage.setItem("session", JSON.stringify(this.session));
                        router.push("/dashboard");
                    }
                }
                return response;
            });
        },
        logout() {
            const baseUrl = `${getApiUrl()}/sessions`;
            if (this.session?.token) {
                $api.delete(`${baseUrl}/${this.session.token}`).then(() => {});
            }
            this.session = null;
            localStorage.removeItem("session");
            router.push("/users/login");
        }
    }
});
