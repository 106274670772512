import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { router } from "./router";
import vuetify from "./plugins/vuetify";
import "@/scss/style.scss";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import VueApexCharts from "vue3-apexcharts";
import VueTablerIcons from "vue-tabler-icons";
// import "vue3-carousel/dist/carousel.css";
//Mock Api data
// import './_mockApis';
// import VueDragscroll from "vue-dragscroll";

// import Maska from "maska";

// Table
// import Vue3EasyDataTable from "vue3-easy-data-table";
// import "vue3-easy-data-table/dist/style.css";
//i18
import { createI18n } from "vue-i18n";
import messages from "@/utils/locales/messages";
import * as Sentry from "@sentry/vue";

//ScrollTop
// import VueScrollTo from 'vue-scrollto';

//LightBox
// import VueEasyLightbox from "vue-easy-lightbox";
import { VueReCaptcha } from "vue-recaptcha-v3";

const i18n = createI18n({
    legacy: false,
    locale: "en",
    messages: messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true
});

const app = createApp(App);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_ENDPOINT,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracePropagationTargets: ["localhost", /^https:\/\/app\.verified\.email/, /^https:\/\/staging\.app\.verified\.email/],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
// app.component("EasyDataTable", Vue3EasyDataTable);
app.use(PerfectScrollbar);
// app.use(VueDragscroll);
app.use(createPinia());

app.use(VueTablerIcons);

app.use(i18n);
// app.use(Maska);
app.use(VueApexCharts);
app.use(vuetify).mount("#app");
app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPTCHA_KEY, loaderOptions: { useEnterprise: true, autoHideBadge: true } });
//ScrollTop Use
// app.use(VueScrollTo);
// app.use(VueScrollTo, {
//     duration: 1000,
//     easing: "ease"
// });

//Lightbox
// app.use(VueEasyLightbox)

export { app, i18n };
