const AuthRoutes = {
    path: "/auth",
    component: () => import("@/layouts/blank/BlankLayout.vue"),
    meta: {
        requiresAuth: false
    },
    children: [
        {
            path: "/",
            redirect: "/dashboard"
        },

        {
            name: "Login",
            path: "/users/login",
            component: () => import("@/views/users/login.vue")
        },
        {
            name: "Register",
            path: "/users/register",
            component: () => import("@/views/users/register.vue")
        },
        {
            name: "Forgot Password",
            path: "/users/password/forgot",
            component: () => import("@/views/users/password/forgot.vue")
        },
        {
            name: "Reset Password",
            path: "/users/password/reset",
            component: () => import("@/views/users/password/reset.vue")
        }
    ]
};

export default AuthRoutes;
