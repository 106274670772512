import type { CaptureContext } from "@sentry/types/build/types/scope";

export { logger };
import { isEnvironment } from "@/utils/helpers/environment";
import * as Sentry from "@sentry/vue";
import type { SeverityLevel } from "@sentry/vue";

const isLogRemotely = import.meta.env.VITE_IS_LOG_REMOTELY === "true";
const isLogConsole = !isEnvironment("production");
const logger = {
    log(level: SeverityLevel, _arguments: any) {
        if (isLogRemotely) {
            logger._logRemotely(level, _arguments);
        }
        if (isLogConsole) {
            logger._logLocally(level, _arguments);
        }
    },
    _logRemotely(level: SeverityLevel, _arguments: any) {
        const a = [..._arguments];
        // SeverityLevels = ['fatal', 'error', 'warning', 'log', 'info', 'debug', 'critical'];
        const message = a.shift();
        let extra = undefined;

        if (a.length === 1) {
            extra = a[0];
        } else if (a.length > 0) {
            extra = a;
        }

        // if(level === "")
        if (level === "log") {
            const parameters: Sentry.Breadcrumb = {
                type: "(type)",
                category: "(category)",
                message: message,
                level: "log",
                data: extra
            };
            Sentry.addBreadcrumb(parameters);
        } else {
            const parameters: CaptureContext = { level };
            if (extra) {
                parameters.extra = extra;
            }
            // Capture top level message with "frontend" emoji
            Sentry.captureMessage(message, parameters);
        }
    },
    _logLocally(level: SeverityLevel, _arguments: any) {
        switch (level) {
            case "warning":
            case "info":
                console.warn.apply(null, _arguments);
                break;
            case "error":
            case "fatal":
                console.error.apply(null, _arguments);
                break;
            default:
                console.log.apply(null, _arguments);
                break;
        }
    },
    notice(...args: any) {
        this.log("log", args);
    },
    info(...args: any) {
        this.log("info", args);
    },
    warning(...args: any) {
        this.log("warning", args);
    },
    error(...args: any) {
        this.log("error", args);
    },
    exception(e: any) {
        console.error(e);
        Sentry.captureException(e);
    }
};
