import en from "./en.json";
import fr from "./fr.json";
import ar from "./ar.json";
import zh from "./zh.json";

const messages = {
    en: en,
    fr: fr,
    ro: ar,
    zh: zh
};

export default messages;
