import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
// import * as directives from "vuetify/directives";
import { VERIFIED_THEME } from "@/theme/LightTheme";

export default createVuetify({
    components: {
        // draggable: VueDraggableNext
    },
    // directives,

    theme: {
        defaultTheme: "VERIFIED_THEME",
        themes: {
            VERIFIED_THEME
        }
    },
    defaults: {
        VCardActions: {
            VBtn: {
                slim: false
            }
        },
        VBtn: {
            color: "primary",
            variant: "flat"
        },

        VCard: {
            rounded: "md"
        },
        VTextField: {
            variant: "outlined",
            density: "comfortable",
            color: "primary"
        },
        VTextarea: {
            variant: "outlined",
            density: "comfortable",
            color: "primary"
        },
        VSelect: {
            variant: "outlined",
            density: "comfortable",
            color: "primary"
        },
        VListItem: {
            // minHeight: "45px"
        },
        VTooltip: {
            location: "top"
        },
        VChip: {
            variant: "outlined"
        }
    }
});
