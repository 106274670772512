export { isEnvironment, getEnvironment };
function getEnvironment(): string {
    if (import.meta.env.PROD) {
        if (location.hostname.startsWith("staging")) {
            return "staging";
        } else {
            return "production";
        }
    }
    return "local";
}
function isEnvironment(environment: string) {
    return getEnvironment() === environment;
}
