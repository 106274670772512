import type { ThemeTypes } from "@/types/themeTypes/ThemeType";

const VERIFIED_THEME: ThemeTypes = {
    name: "VERIFIED_THEME",
    dark: false,
    variables: {
        "border-color": "#e5eaef",
        "border-opacity": 0.5
    },
    colors: {
        primary: '#5D87FF',
        secondary: '#49BEFF',
        info: '#539BFF',
        success: '#00b769',
        accent: '#FFAB91',
        warning: '#FFAE1F',
        error: '#ef0b03',
        lightprimary: '#ECF2FF',
        lightsecondary: '#E8F7FF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightinfo:'#EBF3FE',
        lightwarning: '#FEF5E5',
        textPrimary: '#100a0c',
        textSecondary: '#3b3c3c',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#fff',
        hoverColor: '#f6f9fc',
        "on-surface": "#100a0c",
        surface: '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
    }
};

export { VERIFIED_THEME };
